<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="APP版本管理"
        >
        </a-page-header>
      </div>

      <a-form
        :form="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="handleSubmit"
        style="margin-top: 24px"
      >
        <a-col :span="24" class="title">安卓版本:</a-col>
        <a-form-item label="版本号：">
          <a-input
            v-decorator="[
              'android_version',
              {
                rules: [{ required: true, message: '请输入版本号!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="下载地址：">
          <a-input
            v-decorator="[
              'android_resources_url',
              {
                rules: [{ required: true, message: '请输入下载地址!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="强制更新">
          <a-radio-group
            v-decorator="[
              'android_have_to',
              {
                rules: [{ required: true, message: '请选择是否强制更新!' }],
              },
            ]"
          >
            <a-radio :value="0"> 否 </a-radio>
            <a-radio :value="1"> 是 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="更新说明">
          <a-input
            v-decorator="[
              'android_update_item',
              {
                rules: [{ required: true, message: '请输入更新说明!' }],
              },
            ]"
            placeholder="请输入更新说明。可填写多条，请用'；'分割"
            type="textarea"
          />
        </a-form-item>
        <a-col :span="24" class="title">苹果版本:</a-col>
        <a-form-item label="版本号：">
          <a-input
            v-decorator="[
              'ios_version',
              {
                rules: [{ required: true, message: '请输入版本号!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="下载地址：">
          <a-input
            v-decorator="[
              'ios_resources_url',
              {
                rules: [{ required: true, message: '请输入下载地址!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="强制更新">
          <a-radio-group
            v-decorator="[
              'ios_have_to',
              {
                rules: [{ required: true, message: '请选择是否强制更新!' }],
              },
            ]"
          >
            <a-radio :value="0"> 否 </a-radio>
            <a-radio :value="1"> 是 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="更新说明">
          <a-input
            v-decorator="[
              'ios_update_item',
              {
                rules: [{ required: true, message: '请输入更新说明!' }],
              },
            ]"
            placeholder="请输入更新说明。可填写多条，请用'；'分割"
            type="textarea"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" html-type="submit"> 保存 </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      fileList: [],
      canUpload: "",
    };
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    //获取版本信息
    getSearchList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios.get("/admin/APPVersion/one").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var android_update_item =res.data.data.android_update_item
          var str = "";
          if (android_update_item.length > 0) {
            for (var i = 0; i < android_update_item.length; i++) {
              if (i != 0) {
                str += "；";
              }
              str += android_update_item[i];
            }
          }
          that.android_update_item = str;
          var ios_update_item =res.data.data.ios_update_item
          var str1 = "";
          if (ios_update_item.length > 0) {
            for (var j = 0; j < ios_update_item.length; j++) {
              if (j != 0) {
                str1 += "；";
              }
              str1 += ios_update_item[j];
            }
          }
          that.ios_update_item = str1;
          that.form.setFieldsValue({
            android_have_to: parseInt(res.data.data.android_have_to),
            android_resources_url: res.data.data.android_resources_url,
            android_update_item: that.android_update_item,
            android_version: res.data.data.android_version,
            ios_have_to: parseInt(res.data.data.ios_have_to),
            ios_resources_url: res.data.data.ios_resources_url,
            ios_update_item: that.ios_update_item,
            ios_version: res.data.data.ios_version,
          });
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          // let reg = /^\d\.\d{2}$/;
          // if(!reg.test(values.android_version)){
          //   that.$message.error("安卓版本号格式不正确");
          //   return false;
          // }
          // if(!reg.test(values.ios_version)){
          //   that.$message.error("苹果版本号格式不正确");
          //   return false;
          // }
          var arr = values.android_update_item.split("；");
          var android_update_item = [];
          for (var i = 0; i < arr.length; i++) {
            if (arr[i]) {
              android_update_item.push(arr[i]);
            }
          }
          values.android_update_item = android_update_item;
          var arr1 = values.ios_update_item.split("；");
          var ios_update_item = [];
          for (var j = 0; j < arr1.length; j++) {
            if (arr1[j]) {
              ios_update_item.push(arr1[j]);
            }
          }
          values.ios_update_item = ios_update_item;
          var data = values;
          that.axios.post("/admin/APPVersion/modify", data).then((res) => {
            if (that.$code(res)) {
              that.$message.success("保存成功");
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  height: auto;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
.section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
}
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
.title{
  margin-bottom: 24px;
  font-weight: bold;
}
</style>