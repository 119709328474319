var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"APP版本管理"}})],1),_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.handleSubmit}},[_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("安卓版本:")]),_c('a-form-item',{attrs:{"label":"版本号："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'android_version',
            {
              rules: [{ required: true, message: '请输入版本号!' }],
            } ]),expression:"[\n            'android_version',\n            {\n              rules: [{ required: true, message: '请输入版本号!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"下载地址："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'android_resources_url',
            {
              rules: [{ required: true, message: '请输入下载地址!' }],
            } ]),expression:"[\n            'android_resources_url',\n            {\n              rules: [{ required: true, message: '请输入下载地址!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"强制更新"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'android_have_to',
            {
              rules: [{ required: true, message: '请选择是否强制更新!' }],
            } ]),expression:"[\n            'android_have_to',\n            {\n              rules: [{ required: true, message: '请选择是否强制更新!' }],\n            },\n          ]"}]},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" 否 ")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 是 ")])],1)],1),_c('a-form-item',{attrs:{"label":"更新说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'android_update_item',
            {
              rules: [{ required: true, message: '请输入更新说明!' }],
            } ]),expression:"[\n            'android_update_item',\n            {\n              rules: [{ required: true, message: '请输入更新说明!' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入更新说明。可填写多条，请用'；'分割","type":"textarea"}})],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("苹果版本:")]),_c('a-form-item',{attrs:{"label":"版本号："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'ios_version',
            {
              rules: [{ required: true, message: '请输入版本号!' }],
            } ]),expression:"[\n            'ios_version',\n            {\n              rules: [{ required: true, message: '请输入版本号!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"下载地址："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'ios_resources_url',
            {
              rules: [{ required: true, message: '请输入下载地址!' }],
            } ]),expression:"[\n            'ios_resources_url',\n            {\n              rules: [{ required: true, message: '请输入下载地址!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"强制更新"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'ios_have_to',
            {
              rules: [{ required: true, message: '请选择是否强制更新!' }],
            } ]),expression:"[\n            'ios_have_to',\n            {\n              rules: [{ required: true, message: '请选择是否强制更新!' }],\n            },\n          ]"}]},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" 否 ")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 是 ")])],1)],1),_c('a-form-item',{attrs:{"label":"更新说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'ios_update_item',
            {
              rules: [{ required: true, message: '请输入更新说明!' }],
            } ]),expression:"[\n            'ios_update_item',\n            {\n              rules: [{ required: true, message: '请输入更新说明!' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入更新说明。可填写多条，请用'；'分割","type":"textarea"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }